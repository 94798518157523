<template>
  <v-app>
    <div v-if="isAuth">
      <div class="text-left top-bar mb-6" v-if="!currencyLoading">
        <v-btn elevation="0" v-if="file" @click="copyToClipboard" class="black--text text-lowercase">{{ file }}
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <span class="mx-4">
                  طلا گرمی :
        {{ formatNumber(this.currencies[0].price) }}
        ریال
        </span>
        |
        <span class="mx-4">
                  سکه امامی :
        {{ formatNumber(this.currencies[1].price) }}
        ریال
        </span>
        |
        <span class="mx-4">
                  یورو :
        {{ formatNumber(this.currencies[3].price) }}
        ریال
        </span>
        <span class="mx-4 ml-16">
        |
                  دلار :
        {{ formatNumber(this.currencies[2].price) }}
        ریال
        </span>
        |
        <span class="mx-4">
                  موجودی قابل برداشت :
          <span class="green--text">
        {{ formatNumber($store.state.user.wallet.value) }}
          </span>
        ریال
        </span>
        |
        <span class="mx-4">
                  موجودی غیر قابل برداشت :
          <span class="red--text">
        {{ formatNumber($store.state.user.wallet.inaccessible_value) }}
          </span>
        ریال
        </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :loading="refreshLoading" type="button" small fab color="secondary"
                   v-bind="attrs" v-on="on" class="white--text mx-1 mt-2"
                   @click="refreshManually()">
              <v-icon v-if="!refreshLoading">mdi-database-refresh</v-icon>
            </v-btn>
          </template>
          <span>به روز رسانی دستی تمام واحد های مالی / محصولات / متریال ها</span>
        </v-tooltip>
        <v-menu
            open-on-hover
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                class="text-lowercase persian-button"
                dark
                v-bind="attrs"
                v-on="on"
            >
              <span class="main-text-color">
                <v-icon>mdi-currency-usd</v-icon>
              مالی
              </span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                class="hand-on-hover"
            >
              <v-list-item-title @click="depositDialog = true">
                <v-icon color="primary">mdi-plus</v-icon>
                افزایش موجودی
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                class="hand-on-hover"
            >
              <v-list-item-title @click="withdrawDialog = true">
                <v-icon color="error">mdi-minus</v-icon>
                درخواست برداشت
              </v-list-item-title>
            </v-list-item>

          </v-list>
        </v-menu>
        <v-menu
            open-on-hover
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                class="text-lowercase persian-button"
                dark
                v-bind="attrs"
                v-on="on"
            >
              <span class="main-text-color">
                <v-icon>mdi-account</v-icon>
              {{ $store.state.user.name }}
              </span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                class="hand-on-hover"
            >
              <v-list-item-title @click="openFileManager()">
                <v-icon>mdi-paperclip</v-icon>
                آپلود فایل
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                class="hand-on-hover"
            >
              <v-list-item-title @click="logout()">
                <v-icon>mdi-logout</v-icon>
                خروج
              </v-list-item-title>
            </v-list-item>

          </v-list>
        </v-menu>
      </div>
      <v-dialog v-model="fileDialog" max-width="80vw">
        <drag-and-drop module="articles" ref="file-uploader" @closeUploadFileDialog="fileDialog = false"
                       @changeFile="file = $event"></drag-and-drop>
      </v-dialog>
      <v-dialog v-model="withdrawDialog" max-width="50vw">
        <v-card class="secondary-card">
          <v-card-actions>
            <v-icon color="error" @click="withdrawDialog = false">mdi-close</v-icon>
          </v-card-actions>
          <v-card-text class="mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="withdrawRequest.amount" type="number" label="مبلغ به ریال وارد شود"
                              hint="نمی تواند بیشتر از موجودی کیف پولتان باشد." color="black">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="withdrawRequest.card_number" label="شماره کارت یا شبا وارد کنید."
                              hint="برای مبالغ بیش از ۱۰ میلیون تومان شماره شبا وارد کنید." color="black">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="withdrawRequest.description" label="توضیحات"
                            hint="برای مبالغ بیش از ۱۰۰ میلیون تومان حتما توضیحات را وارد کنید." color="black">
                </v-textarea>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn class="persian-button main-button" @click="withdraw()">تایید</v-btn>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="depositDialog" max-width="30vw">
        <v-card class="secondary-card">
          <v-card-actions>
            <v-icon color="error" @click="depositDialog = false">mdi-close</v-icon>
          </v-card-actions>
          <v-card-text class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="depositRequest.amount" type="number" label="مبلغ به ریال وارد شود"
                              hint="نمی تواند بیشتر از۱۰۰ میلیون تومان در روز باشد." color="black">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="withdrawRequest.description" label="توضیحات"
                            color="black">
                </v-textarea>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn class="persian-button main-button" @click="deposit()">تایید</v-btn>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-main class="main-background" v-if="!loading && isAuth">
      <navbar v-if="$route.path !== '/login'"></navbar>
      <global-snack-bar class="snackbar-text"></global-snack-bar>
      <router-view/>
    </v-main>
    <router-view v-else/>
  </v-app>
</template>
<script>
import GlobalSnackBar from "@/components/Notification/GlobalSnackBar";
import Navbar from "@/components/Navbar/Navbar";
import DragAndDrop from "@/components/UploadFile/DragAndDrop.vue";

export default {
  name: 'App',
  components: {DragAndDrop, Navbar, GlobalSnackBar},
  data: () => ({
    loading: true,
    currencyLoading: true,
    refreshLoading: false,
    isAuth: false,
    currencies: [],
    fileDialog: false,
    withdrawDialog: false,
    depositDialog: false,
    file: null,
    menuTopBar: true,
    withdrawRequest: {
      amount: 0,
      card_number: null,
      description: null,
    },
    depositRequest: {
      amount: 0,
      description: null,
    }
  }),
  async beforeMount() {
    await this.getProfile()
    this.getCurrencies()
  },
  methods: {
    getCurrencies() {
      window.axios.get('admin/currency/get-main').then((res) => {
        this.currencies = res.data.data
        this.currencyLoading = false
      })
    },
    refreshManually() {
      if (!this.refreshLoading) {
        this.refreshLoading = true
        window.axios.get('admin/currency/refresh').then((res) => {
          this.openSnackbar(res.data.message, '#413b3b')
          this.refreshLoading = false
          this.getCurrencies()
        })
      }
    },
    formatNumber(value) {
      const numericValue = parseFloat(value);
      return numericValue.toLocaleString();
    },
    deposit() {
      window.axios.post('admin/wallet/charge', this.depositRequest).then((res) => {
        this.openSnackbar(res.data.message, '#413b3b')
        this.getProfile()
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err, '#F1416C')
        } else if (err.response.status === 400 || err.response.status === 404) {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
      this.depositDialog = false
    },
    withdraw() {
      window.axios.post('admin/withdraw/create', this.withdrawRequest).then((res) => {
        this.openSnackbar(res.data.message, '#413b3b')
        this.getProfile()
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err, '#F1416C')
        } else if (err.response.status === 400 || err.response.status === 404) {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
      this.withdrawDialog = false
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.file).then(() => {
        this.openSnackbar('کپی شد.', '#15C15D')
      }, () => {
      });
    },
    openFileManager() {
      this.fileDialog = true
    },
    logout() {
      if (this.$store.state.user.role.name === 'seller') {
        window.axios.get('admin/seller/auth/logout').then(() => {
          this.$cookies.remove('token')
          this.$router.go(0)
        })
      } else {
        window.axios.get('admin/auth/logout').then(() => {
          this.$cookies.remove('token')
          this.$router.go(0)
        })
      }
    },
    async getProfile() {
      if (this.$store.state.user) {
        this.$store.state.user = null
      }
      await window.axios.get('admin/auth/profile').then((res) => {
        this.$store.state.user = res.data.data
        this.$store.state.auth = true
        this.isAuth = true
        this.$store.state.loading = false
        this.loading = false
        if (this.$route.path === '/login') {
          this.$router.push('/')
        }
      }).catch(() => {
        if (this.$route.path !== '/login')
          this.$router.push('/login')
        this.loading = false
      })
    }
  }
};
</script>
<style>
@font-face {
  font-family: YekanBakh;
  src: url("../src/assets/fonts/yekan/YekanBakhFaNum-Regular.woff");
}
* {
  font-family: YekanBakh, serif;
}

.main-background {
  background-size: cover;
  margin-right: 6vw;
  margin-left: 1vw;
}

.persian-button {
  letter-spacing: normal !important;
}

.top-bar {
  height: 8vh !important;
  background-color: #f7f8fa;
  //border-style: solid;
  //border-color: #FFD700;
  box-shadow: 0px 5px 10px 10px rgba(255, 215, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(236, 185, 31, 0.12) !important;
}

.hand-on-hover {
  cursor: pointer;
}

.main-text-color {
  color: #413b3b;
}

.error-button {
  color: white !important;
  letter-spacing: normal !important;
  background-color: #f13131 !important;
}

.error-button:hover {
  background-color: #FFD700 !important;
  color: #413b3b !important;
}
</style>